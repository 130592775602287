import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Link from 'react-router-dom/Link';
import Table from 'reactstrap/lib/Table';
import cookie from 'react-cookies';

import SortHeaderComponent from 'components/common/SortHeader';
import { secondsToHours,secondsToHHMM } from 'helpers/utils';
import styles from './styles.module.scss';

import InactiveIcon from './InactiveIcon.png';
/**
 * function is used to map adjustment for the tooltip shown for ADJ in table 
 *
 * @param {object} data : contains total data for the adjustments
 * @param {boolean} decimalView : true for decimal view and false for list view
 * @return {JSX} : jsx for tooltip
 */
function getAdjustmentTooltipData(data , decimalView){
  return data?.map(i=>{
    return <div>
      {i?.name}: {decimalView? secondsToHours(i?.total_adjusted_time) : secondsToHHMM(i?.total_adjusted_time)}
    </div>
  })
}

/**
 *
 * function is used to map PTOs data for the tooltip shown for PTO in table
 *
 * @param {Array} data : array of PTOs data 
 * @param {boolean} decimalView : true for decimal view and false for list view
 * @return {JSX} :  jsx for tooltip
 */
function getPTOsTooltipData(data,decimalView){
  return data?.map(i=> {
    return <div>
      {i?.policy_name}: {decimalView? secondsToHours(i?.total) : secondsToHHMM(i?.total)}
    </div>
  })  
}

/**
 * TimecardListPage -> TimecardListSection -> CustomTimecardTableComponent
 *
 * Props:
 *    - timecard list
 */
class CustomTimecardTableComponent extends React.Component{
  constructor(props){
    super(props);
  }
  componentDidMount(){
    const { location: { query } } = this.props;
    const elementToScroll = document.getElementById(+query?.emp_id);
    if(elementToScroll){
      elementToScroll.scrollIntoView(true);
    }
  }


  render(){
    const { data, location, updateSelected, updateAll, showableAttributes, userExtraData } = this.props;    
    const { query } = location;
    const showEmployeeCookie = cookie.load('isShowEmployeeListCookie') && cookie.load('isShowEmployeeListCookie') == 'true' ? true : false;
    let parsedParams;
    if(Object.keys(query).length) parsedParams = query.show_extra_info && JSON.parse(query.show_extra_info);
    return (
      <div className="ml-3 mr-4">
        <Table responsive hover size="sm" className="mt-1 table-timecard-main">
          <thead>
            <tr className="Header-col">
              <th width="10%" className="action_col pt-3 pl-3">
                <span className="v-mid">
                  <input
                    type="checkbox"
                    value={data.results && data.results[0] && data.results[0].allSelected}
                    onChange={() => updateAll(data.results)}
                    title="Select to export all the timecards"
                  />
                </span>
              </th>
              {showableAttributes.first_name &&
                <SortHeaderComponent ordering="employee__user__first_name" style={{ width: '11%' }}>First Name</SortHeaderComponent>
              }
              {showableAttributes.last_name &&
                <SortHeaderComponent ordering="employee__user__last_name" style={{ width: '11%' }}>Last Name</SortHeaderComponent>
              }
              {showableAttributes.employee_id &&
                <SortHeaderComponent ordering="employee__employee_id">Employee Id</SortHeaderComponent>
              }
              {showableAttributes.employee_title &&
                <SortHeaderComponent ordering="employee__title">Employee Title</SortHeaderComponent>
              }
              <th hidden={!showableAttributes.punch_in_time} className="text-right">Punch-In Time</th>
              <th hidden={!showableAttributes.meal_period_time} className="timecard-blank-col">&nbsp;</th>
              <th hidden={!showableAttributes.meal_period_time} className="text-right">Meal Period Time</th>
              <th hidden={!showableAttributes.total_time} className="timecard-blank-col">&nbsp;</th>
              <th hidden={!showableAttributes.total_time} className="text-right">Total Time</th>
              <th hidden={!showableAttributes.regular_time} className="timecard-blank-col">&nbsp;</th>
              <th hidden={!showableAttributes.regular_time} className="text-right">Regular Time</th>
              <th hidden={!showableAttributes.overtime} className="timecard-blank-col">&nbsp;</th>
              <th hidden={!showableAttributes.overtime} className="text-right">Overtime</th>
              <th hidden={!showableAttributes.double_overtime} className="timecard-blank-col">&nbsp;</th>
              <th hidden={!showableAttributes.double_overtime} className="text-right">Double Overtime</th>
              <th hidden={false} className="timecard-blank-col">&nbsp;</th>
              <th hidden={false} className="text-right">ADJ</th>
              <th hidden={false} className="timecard-blank-col">&nbsp;</th>
              <th hidden={false} className="text-right">PTO</th>
              {userExtraData.company_permissions.mileage ?
                <th hidden={!showableAttributes.mileage} className="text-right pr-3">
                  Mileage
                  <div className={`${styles['betaflag-custom']}`}>
                    BETA
                  </div>
                </th> : null
              }
              {/* <th>Approval</th> */}
            </tr>
          </thead>
          <tbody className="timecardTD timeCard-table">
            {data.results && data?.results?.map((item,idx) => {
              let url = `/timecard/custom/list/${item.employee__id}/daily`;
              const cookieKey = cookie.load('listView');
              let listview = false;
              if (cookieKey) {
                listview = JSON.parse(cookieKey);
              }
              if (listview) {
                url = `/timecard/custom/list/${item.employee__id}/dailyV0`
              }
              let adjustmentData=item?.employee_dt_adjustments?.reduce((acc,cur)=> (acc+cur?.total_adjusted_time),0)
              let ptoData= item?.employee_pto_mapping?.map(i=> ({policy_name:i?.policy_name,total:( i?.rt || 0 ) + ( i?.ot || 0 ) + ( i?.dot || 0 )}))
              return (
                <tr
                key={item.id}
                // className={item.need_correction ? 'bgRed' : null}
                style={(item.color === '') ? { backgroundColor: '#FFF' } : { background: item.color }}
                >
                  <td colSpan={userExtraData.company_permissions.mileage?"21":"20"} className="p-0">
                    <Table
                      className={`mb-0 mt-0 table-blank`}
                      >
                      <tbody>
                      <tr key={item.id} id={item.employee__id}  style={(item.color === '') ? { backgroundColor: '#FFF' } : { background: item.color }}>
                        <td className="action_col pl-3">
                          <input
                            type="checkbox"
                            checked={item.isSelected}
                            onChange={() => updateSelected(item)}
                            name={item.isSelected}
                            title="Select to export the timecards"
                          />
                        </td>
                        <td hidden={!showableAttributes.first_name} title={item.employee__user__first_name}>
                          {!item.employee__is_active && <img alt="" src={InactiveIcon} height="10" width="10" style={{ marginRight: '2px', marginBottom: '2px' }} />}
                          <span>
                            <Link to={{
                              pathname: url,
                              search: queryString.stringify({
                                  parent: JSON.stringify({...query, emp_id: item.employee__id }),
                                  start_date: query.start_date,
                                  emp_dept_id: query.department_ids,
                                  end_date: query.end_date,
                                  employee_id: item.employee__id,
                                  employee_id_show: item.employee__employee_id,
                                  employee_team_name: item.employee__team__name,
                                  employee_title: item.employee__title,
                                  first_name: item.employee__user__first_name,
                                  last_name: item.employee__user__last_name,
                                  empployee_dept_name: item.employee__department__name,
                                  custom: true,
                                  department_ids: query.department_ids,
                                  team_ids: query.team_ids,
                                  payroll_id: query.payroll_id,
                                  show_employee_list: showEmployeeCookie,
                                  employee__user_id: item.employee__user_id,
                                }),
                              }}>
                                {item.employee__user__first_name}
                              </Link>
                            </span>
                          </td>
                          <td hidden={!showableAttributes.last_name} title={item.employee__user__last_name}>{item.employee__user__last_name}</td>
                          <td hidden={!showableAttributes.employee_id} title={item.employee__employee_id}>{item.employee__employee_id}</td>
                          <td hidden={!showableAttributes.employee_title} title={item.employee__title}>{item.employee__title}</td>
                          <td hidden={!showableAttributes.punch_in_time} title={item.timecard_punch_in_time_display} className="text-right">{item.timecard_punch_in_time_display}</td>
                          <td hidden={!showableAttributes.meal_period_time} className="timecard-blank-col-td">&nbsp;</td>
                          <td hidden={!showableAttributes.meal_period_time} className={item.timecard_meal_paid_time !== 0 && item.timecard_meal_unpaid_time !== 0 ? "pt-0 " : null} className="text-right">
                            {(item.timecard_meal_paid_time === 0 && item.timecard_meal_unpaid_time === 0) ?
                              <div>{item.timecard_meal_paid_time_display}</div>
                              :
                              null
                            }
                            {item.timecard_meal_paid_time !== 0 ?
                              <div style={item.timecard_meal_unpaid_time !== 0 ? { fontSize: '11px', paddingTop: '2px' } : null}>{item.timecard_meal_unpaid_time !== 0 ? 'P: ' : null}{item.timecard_meal_paid_time_display}</div>
                              :
                              null
                            }
                            {item.timecard_meal_unpaid_time !== 0 ?
                              <div style={item.timecard_meal_paid_time !== 0 ? { fontSize: '11px', paddingTop: '2px', color: '#EC4409' } : { color: '#EC4409' }}>{item.timecard_meal_paid_time !== 0 ? 'U: ' : null}{(item.timecard_meal_paid_time === 0 && item.timecard_meal_unpaid_time !== 0) ? '-' : null}{item.timecard_meal_unpaid_time_display}</div>
                              :
                              null
                            }
                          </td>
                          <td hidden={!showableAttributes.total_time} className="timecard-blank-col-td">&nbsp;</td>
                          <td hidden={!showableAttributes.total_time} title={item.timecard_total_paid_time_display} className="text-right">{item.timecard_total_paid_time_display}</td>
                          <td hidden={!showableAttributes.regular_time} className="timecard-blank-col-td">&nbsp;</td>
                          <td hidden={!showableAttributes.regular_time} title={item.timecard_regular_time_display} className="text-right"><strong>{item.timecard_regular_time_display}</strong></td>
                          <td hidden={!showableAttributes.overtime} className="timecard-blank-col-td">&nbsp;</td>
                          <td hidden={!showableAttributes.overtime} title={item.timecard_over_time_display} className="text-right"><strong>{item.timecard_over_time_display}</strong></td>
                          <td hidden={!showableAttributes.double_overtime} className="timecard-blank-col-td">&nbsp;</td>
                          <td hidden={!showableAttributes.double_overtime} title={item.timecard_double_over_time_display} className="text-right"><strong>{item.timecard_double_over_time_display}</strong></td>
                          <td hidden={false} className="timecard-blank-col-td">&nbsp;</td>
                          <td hidden={false} className="text-right" >
                            <strong id={`adj__${idx}`}>
                              {query?.timecard_decimal_view==='true'? secondsToHours(adjustmentData) : secondsToHHMM(adjustmentData) }
                            </strong>
                              <UncontrolledTooltip placement="left" target={`adj__${idx}`}>
                             {item?.employee_dt_adjustments?.length > 0?
                                getAdjustmentTooltipData(item?.employee_dt_adjustments , query?.timecard_decimal_view==='true'):
                                  <div>No adjustments found</div>
                              }
                              </UncontrolledTooltip>
                          </td>
                          <td hidden={false} className="timecard-blank-col-td">&nbsp;</td>
                          <td hidden={false} className="text-right">
                            <strong id={`pto__${idx}`}>
                              {query?.timecard_decimal_view==='true'
                                ? secondsToHours(ptoData?.reduce((acc,curr)=> acc+curr?.total,0)) 
                                : secondsToHHMM(ptoData?.reduce((acc,curr)=> acc+curr?.total,0))}
                            </strong>
                              <UncontrolledTooltip placement="left" target={`pto__${idx}`}  >
                                {ptoData?.length > 0  ?
                                  getPTOsTooltipData(ptoData, query?.timecard_decimal_view==='true'):
                                  <div>No paid time off found</div>
                                }
                              </UncontrolledTooltip>
                            </td>
                          {userExtraData.company_permissions.mileage ?
                            <td hidden={!showableAttributes.mileage} title={item.timecard_mileage.toFixed(1)} className="text-right pr-3"><strong>{item.timecard_mileage.toFixed(1)}</strong></td> : null
                          }
                          {/* {item.is_approved
                          ? <td title={item.approved_datetime_display}>{item.approved_datetime_display}</td>
                          : (<td>
                            <Button
                              type="button"
                              size="sm"
                              color={
                                item.need_correction || (new Date() < new Date(item.end_date))
                                  ? 'default'
                                  : 'secondary'
                              }
                              disabled={item.need_correction || (new Date() < new Date(item.end_date))}
                              onClick={() => approveTimecard(item)}
                            >
                              Approve
                            </Button>
                          </td>)
                        } */}
                        </tr>
                      </tbody>
                    </Table>
                    {parsedParams && showableAttributes.first_name && (item.employee__department__name || item.employee__team__name) &&
                      <div className={`${styles['highlight-text__white']}`}>
                        <span>{item.employee__department__name ?
                          (<span><i> {'('} {item.employee__department__name}</i>
                          </span>) : null}
                        </span>
                        <span>{item.employee__department__name && !item.employee__team__name ?
                          (<span><i>{')'}</i>
                          </span>) : null}
                        </span>
                        <span>{item.employee__department__name && item.employee__team__name ?
                          (<span><i> {'>'} {item.employee__team__name}{')'}</i>
                          </span>) : null}
                        </span>
                      </div>
                    }
                  </td>
                </tr>
              )
            })}
            {!data.count &&
              <tr><td colSpan="10" className='text-center'> No Records Found </td></tr>
            }
          </tbody>
        </Table>
      </div>
    );
  }
}


CustomTimecardTableComponent.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  approveTimecard: PropTypes.func.isRequired,
};

export default CustomTimecardTableComponent;
